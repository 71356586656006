<template>
  <section class="invoice-preview-wrapper">
    <div class="position-relative">
      <b-card class="invoice-preview-card">
        <div class="d-flex justify-content-between flex-wrap">
          <h2 class="my-auto">{{ $t('profil.title') }}<br /></h2>
        </div>
      </b-card>
      <b-row>
        <b-col cols="6">
          <b-card>
            <b-card-header class="py-1">
              <h4>{{ $t('profil.main_info_title') }}</h4>
            </b-card-header>
            <hr />
            <div>
              <!-- lastname fistname -->
              <b-col cols="12">
                <h5>
                  <strong>{{ $t('profil.main_info_fullname') }}: </strong>
                  <!-- <span> {{ userData.fullname }}</span> -->
                  <!-- @change="
                    () => {
                      $emit('on_change_checklist_object', checklist_object);
                    }
                  " -->
                  <b-form-input v-model="userData.lastname" class="col-12" />
                  <b-form-input v-model="userData.firstname" class="col-12" />
                </h5>
              </b-col>
              <!-- email -->
              <b-col cols="12 mt-1">
                <h5>
                  <strong>{{ $t('profil.main_info_email') }}: </strong>
                  <span> {{ userData.email }}</span>
                </h5>
              </b-col>
              <!-- roles -->
              <!-- <b-col cols="12 mt-1">
                <h5>
                  <strong>{{ $t("profil.main_info_role") }}: </strong>
                  <span>
                    <b-badge
                      v-for="role in userData.roles"
                      :key="role.id"
                      variant="light-primary"
                    >
                      {{ role.title }}
                    </b-badge></span
                  >
                </h5>
              </b-col> -->
              <!-- singature -->
              <!-- userData.signature= {{ userData.signature }} -->
              <!-- <b-col cols="12 mt-1">
                <h5>
                  <strong class=""
                    >{{ $t("profil.main_info_signature") }}:
                  </strong>
                  <iframe
                    :src="signature_url ? signature_url : fileContent"
                    style="width: 100%; height: 30vh; border: none"
                    class="mt-1"
                  >
                    Oops! aucun fichier.
                  </iframe>
                  <b-form-file
                    class="mt-1"
                    placeholder="Veuillez choisir votre signature au format PDF"
                    drop-placeholder="Glissez-déposez le fichier ici..."
                    accept="image/jpeg, application/pdf"
                    :state="!isValidSignatureFile(signature_url) ? false : null"
                    @change="handleFileChange"
                  />
                </h5>
              </b-col> -->
              <b-row class="d-flex justify-content-end mt-1">
                <b-button variant="primary" @click="changeInfos()">
                  <feather-icon
                    v-if="!isLoading_update_user_data"
                    icon="SaveIcon"
                  /><b-spinner v-else small />
                  &nbsp;
                  {{ $t('button.edit_form') }}
                </b-button>
              </b-row>
            </div>
          </b-card>
        </b-col>
        <b-col cols="6">
          <b-card>
            <b-card-header class="py-1">
              <h4>{{ $t('profil.update_password_title') }}</h4>
            </b-card-header>
            <hr />
            <validation-observer ref="formRules">
              <b-row>
                <!-- old password -->
                <b-col md="12">
                  <b-form-group
                    :label="$t('profil.old_password_label')"
                    label-cols-md="12"
                    label-for="title"
                    class="password-field"
                  >
                    <validation-provider v-slot="{ errors }" rules="required">
                      <b-input-group class="input-group-merge">
                        <b-form-input
                          id="old_pass"
                          v-model="form.old_password"
                          class="form-control-merge"
                          :placeholder="
                            $t('profil.old_password_input_placeholder')
                          "
                          :type="passwordFieldType"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="
                              passwordFieldType === 'password'
                                ? 'EyeIcon'
                                : 'EyeOffIcon'
                            "
                            class="cursor-pointer"
                            @click="togglePasswordInputType"
                          />
                        </b-input-group-append>
                      </b-input-group>

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- new password -->
                <b-col md="12">
                  <b-form-group
                    :label="$t('profil.new_password_label')"
                    label-cols-md="12"
                    label-for="password"
                    class="password-field"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="$v.form.password.$error ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="password"
                        v-model="form.password"
                        class="form-control-merge"
                        :placeholder="
                          $t('profil.new_password_input_placeholder')
                        "
                        :type="passwordFieldType"
                        :disabled="!form.old_password"
                        :state="$v.form.password.$error ? false : null"
                        @focus="passwordFieldIsFocused = true"
                        @blur="
                          passwordFieldIsFocused = false
                          $v.form.password.$touch
                        "
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="
                            passwordFieldType === 'password'
                              ? 'EyeIcon'
                              : 'EyeOffIcon'
                          "
                          class="cursor-pointer"
                          @click="togglePasswordInputType"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <div
                      v-if="passwordFieldIsFocused || $v.form.password.$error"
                      class="invalid-feedback password-rules"
                    >
                      <!-- v-if="
                      passwordFieldIsFocused ||
                      $v.form.password.isValid.$invalid
                    " -->
                      <small>
                        <span class="label">
                          Le mot de passe doit contenir :
                        </span>
                        <ul class="list-style-none password-validators-errors">
                          <li
                            :class="{
                              valid: passwordValidators(form.password)
                                .atLeastOneCapLetter,
                            }"
                          >
                            <div class="icons">
                              <i
                                v-if="
                                  !passwordValidators(form.password)
                                    .atLeastOneCapLetter
                                "
                                class="las la-times-circle"
                              />
                              <i
                                v-if="
                                  passwordValidators(form.password)
                                    .atLeastOneCapLetter
                                "
                                class="las la-check-circle"
                              />
                            </div>
                            au moins une lettre majuscule
                          </li>
                          <li
                            :class="{
                              valid: passwordValidators(form.password)
                                .atLeastOneMinusLetter,
                            }"
                          >
                            <div class="icons">
                              <i
                                v-if="
                                  !passwordValidators(form.password)
                                    .atLeastOneMinusLetter
                                "
                                class="las la-times-circle"
                              />
                              <i
                                v-if="
                                  passwordValidators(form.password)
                                    .atLeastOneMinusLetter
                                "
                                class="las la-check-circle"
                              />
                            </div>
                            au moins une lettre minuscule
                          </li>
                          <li
                            :class="{
                              valid: passwordValidators(form.password)
                                .atLeastOneDigits,
                            }"
                          >
                            <div class="icons">
                              <i
                                v-if="
                                  !passwordValidators(form.password)
                                    .atLeastOneDigits
                                "
                                class="las la-times-circle"
                              />
                              <i
                                v-if="
                                  passwordValidators(form.password)
                                    .atLeastOneDigits
                                "
                                class="las la-check-circle"
                              />
                            </div>
                            au moins un chiffre
                          </li>
                          <li
                            :class="{
                              valid: passwordValidators(form.password)
                                .atLeastOneSpecialChar,
                            }"
                          >
                            <div class="icons">
                              <i
                                v-if="
                                  !passwordValidators(form.password)
                                    .atLeastOneSpecialChar
                                "
                                class="las la-times-circle"
                              />
                              <i
                                v-if="
                                  passwordValidators(form.password)
                                    .atLeastOneSpecialChar
                                "
                                class="las la-check-circle"
                              />
                            </div>
                            au moins un caractère special (@#$%^&+=)
                          </li>
                          <li
                            :class="{
                              valid: passwordValidators(form.password)
                                .minimumLength,
                            }"
                          >
                            <div class="icons">
                              <i
                                v-if="
                                  !passwordValidators(form.password)
                                    .minimumLength
                                "
                                class="las la-times-circle"
                              />
                              <i
                                v-if="
                                  passwordValidators(form.password)
                                    .minimumLength
                                "
                                class="las la-check-circle"
                              />
                            </div>
                            au moins 8 caractères
                          </li>
                        </ul>
                      </small>
                    </div>
                  </b-form-group>
                </b-col>
                <!-- confirm new password -->
                <b-col md="12">
                  <b-form-group
                    :label="$t('profil.confirm_password_label')"
                    label-cols-lg="12"
                    label-for="password_confirmation"
                    class="password-field"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="
                        $v.form.password_confirmation.$error
                          ? 'is-invalid'
                          : null
                      "
                    >
                      <b-form-input
                        id="password_confirmation"
                        v-model="form.password_confirmation"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        :placeholder="
                          $t('profil.confirm_password_input_placeholder')
                        "
                        :disabled="!form.password"
                        @blur="$v.form.password_confirmation.$touch"
                        placeholder="············"
                        :state="
                          $v.form.password_confirmation.$error ? false : null
                        "
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="
                            passwordFieldType === 'password'
                              ? 'EyeIcon'
                              : 'EyeOffIcon'
                          "
                          class="cursor-pointer"
                          @click="togglePasswordInputType"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <div v-if="$v.form.password_confirmation.$error">
                      <small
                        v-if="$v.form.password_confirmation.isValid.$invalid"
                        class="text-danger"
                      >
                        Les mots de passe ne sont pas identiques
                      </small>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="text-right">
                <b-button variant="primary" @click="changePassword()">
                  <feather-icon v-if="!loadingData" icon="SaveIcon" /><b-spinner
                    v-else
                    small
                  />
                  &nbsp;
                  {{ $t('button.edit_form') }}
                </b-button>
              </div>
            </validation-observer>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BRow,
  BCol,
  BFormInput,
  BFormCheckbox,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSpinner,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  VBTooltip,
  BFormSelect,
  BDropdownDivider,
  BCardTitle,
  BCardText,
  BFormFile,
} from 'bootstrap-vue'

import { mapActions } from 'vuex'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import useVuelidate from '@vuelidate/core'
import passwordValidators from '@/helpers/password-validators'
import { required, email } from '@vuelidate/validators'

import localstorageService from '@/services/localstorage/localstorage.service'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import PaginationComponent from '@/components/PaginationComponent.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import userProfilStoreModule from '@/store/user-profil'
import usersStoreModule from '@/store/users'

import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BCardBody,
    BCardHeader,
    flatPickr,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormSelect,
    BDropdownDivider,
    BCardTitle,
    BCardText,
    BFormFile,

    ValidationProvider,
    ValidationObserver,
    PaginationComponent,
    ToastificationContent,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  setup() {
    const requiredStoreModules = [
      { path: 'user-profil', module: userProfilStoreModule },
      { path: 'users', module: usersStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)
    return {
      requiredStoreModules,
      passwordValidators,
      $v: useVuelidate({ $lazy: true }),
    }
  },
  data() {
    return {
      userData: null,
      loadingData: false,
      form: {
        old_password: '',
        password: '',
        password_confirmation: '',
      },
      fileContent: null,
      isLoading_update_user_data: false,
      signature_url: null,

      passwordFieldType: 'password',
      passwordFieldIsFocused: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    $route: {
      immediate: true,
      async handler(val, old) {
        this.userData = localstorageService.getUserData()
        // console.log("this.userData.signature::: ", this.userData.signature);
        if (this.userData.signature) {
          this.signature_url = this.userData.signature.original_url
          // console.log("this.signature_url::: ", this.signature_url);
        }
      },
    },
    passwordFieldIsFocused: {
      immediate: false,
      deep: false,
      handler(val, old) {
        this.$v.form.password.$validate()
      },
    },
  },
  beforeDestroy() {
    // unregisterStoreModule(this.requiredStoreModules);
  },
  validations() {
    return {
      form: {
        password: {
          required,
          isValid(value) {
            return this.passwordValidators(value).isValid
          },
        },
        password_confirmation: {
          isValid: (val) => val && val === this.form.password,
        },
      },
    }
  },
  mounted() {},
  methods: {
    ...mapActions('user-profil', {
      action_changePassword: 'changePassword',
    }),
    ...mapActions('users', {
      action_updateUser: 'updateUser',
      action_updateUserFormData: 'updateUserFormData',
    }),
    //---------------------------------------------------
    async changePassword() {
      if (this.loadingData) return
      await this.$refs.formRules
        .validate()
        .then((success) => {
          if (success == true) {
            this.loadingData = true
            this.action_changePassword(this.form)
              .then((response) => {
                if (response.status == 200) {
                  this.form = {
                    old_password: 'null',
                    password: 'null',
                    password_confirmation: 'null',
                  }
                  this.loadingData = false
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Mot de passe changé avec success',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: '',
                    },
                  })
                }
              })
              .catch((error) => {
                this.loadingData = false
                console.log('error', error)
                if (error.response.data && error.response.data.length !== 0) {
                  this.error = error.response.data
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title:
                      error.response.data.message || 'Identifiants incorrects',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
        .catch((error) => {
          console.log(error)
          this.loadingData = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Une erreur est survenue',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: '',
            },
          })
        })
    },
    changeInfos() {
      this.isLoading_update_user_data = true
      const formDataForSending = new FormData()
      formDataForSending.append('lastname', this.userData.lastname || null)
      formDataForSending.append('firstname', this.userData.firstname || null)
      if (this.userData.signature !== null) {
        formDataForSending.append('signature', this.userData.signature || null)
      }
      // for (const pair of formDataForSending.entries()) {
      //   console.log(`${pair[0]}: ${pair[1]}`);
      // }
      // return;
      this.action_updateUserFormData({
        id: this.userData.id,
        data: formDataForSending,
      })
        .then((result) => {
          if (result.status === 202) {
            localstorageService.setUserSignature(result.data.data.signature)
            this.isLoading_update_user_data = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title:
                  'Modifications enregistrées. Elles seront prise en compte à la prochaine connexion.',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch((err) => {
          console.log('err::: ', err)
          this.isLoading_update_user_data = false
        })
    },
    handleFileChange(event) {
      // Récupérer le fichier à partir de l'événement de changement
      const file = event.target.files[0]
      this.userData.signature = file

      // Créer une URL pour le fichier et l'assigner à fileUrl pour affichage dans l'iframe
      // this.fileUrl = URL.createObjectURL(this.file);

      // Lire le contenu du fichier en utilisant FileReader
      const reader = new FileReader()
      reader.onload = (e) => {
        this.fileContent = e.target.result
      }
      reader.readAsDataURL(file)
    },
    // utils -------------------------------------------
    togglePasswordInputType() {
      // eslint-disable-next-line no-unused-expressions
      this.passwordFieldType =
        this.passwordFieldType === 'password' ? 'text' : 'password'
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
@import '@core/scss/base/pages/app-invoice.scss';

.per-page-selector {
  width: 90px;
}

.main-grid {
  display: grid;
  grid-template-columns: auto 400px;
  column-gap: 30px;
  position: relative;

  .options-sidebar {
    background: #fff;
    overflow: auto;
    height: 80vh;
    padding-bottom: 59px;
    position: fixed;
    right: 1.5rem;
    width: 400px;
  }
}

.v-select {
  width: 100%;
}

// Medium devices (tablets, 768px and up)
@media (max-width: 768px) {
  .main-grid {
    display: unset;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    row-gap: 30px;
    position: relative;

    .options-sidebar {
      overflow: auto;
      height: unset;
      position: unset;
      width: unset;
    }
  }
}

.d-content {
  display: contents;
}

#sidebar-wrapper {
  position: fixed;
  right: 250px;
  width: 100%;
  height: 100%;
  margin-right: -90%;
  overflow-y: auto;

  .sidecard {
    margin: 0 0 800px 0;
  }
}

@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }

  nav.header-navbar {
    display: none;
  }

  .main-menu {
    display: none;
  }

  .header-navbar-shadow {
    display: none !important;
  }

  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }

  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>

import userProfilService from "@/services/http/user-profil.service";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async changePassword({ rootState }, data) {
      const res = await userProfilService.changePassword(data);
      return res;
    },
  },
};

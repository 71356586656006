import { BaseService } from './base.service'

export class Profil extends BaseService {
    // eslint-disable-next-line no-useless-constructor
    constructor() {
        super()
    }


    changePassword(data) {
        return new Promise((resolve, reject) => {
            this.axiosInstance
                .post(`/change-password`, data)
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    }

}

export default new Profil()
